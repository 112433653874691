
import { defineComponent, ref } from 'vue';
import CodeEditor from '@/components/code/CodeEditor.vue';

export default defineComponent({
  name: 'codemirrorpage',
  components: {
    CodeEditor,
  },
  setup(props, { emit }) {
    const code = ref<string>('a = "Hello Word!"');
    const mode = 'python';

    const sendMessage = () => {
      emit('send-message');
    };
    return {
      code,
      mode,
      sendMessage,
    };
  },
});
