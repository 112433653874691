<template>
  <page-container2>
    <a-row type="flex" style="height: 100%; overflow-y: hidden">
      <a-col flex="400px">100px</a-col>
      <a-col flex="1 1 500px" style="height: 100%; overflow-y: hidden">
        <code-editor
          v-model:editorValue="code"
          :mode="mode"
          @debounce-update="sendMessage"
        ></code-editor>
      </a-col>
      <a-col flex="0 0 400px">100px</a-col>
    </a-row>
  </page-container2>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import CodeEditor from '@/components/code/CodeEditor.vue';

export default defineComponent({
  name: 'codemirrorpage',
  components: {
    CodeEditor,
  },
  setup(props, { emit }) {
    const code = ref<string>('a = "Hello Word!"');
    const mode = 'python';

    const sendMessage = () => {
      emit('send-message');
    };
    return {
      code,
      mode,
      sendMessage,
    };
  },
});
</script>
